<template>
    <div class="recent-read">
        <HeaderComp class="activity-header" title="阅读历史" @return="doBack" />
        <div v-if="afterLoad && historyComicsList.length === 0" class="content-wrap">
            <div class="empty"></div>
            <div class="msg">你还没有阅读记录&gt;ω&lt;</div>
        </div>
        <div v-else class="content-wrap">
            <template v-if="historyComicsList.length">
                <div class="comics-list">
                    <Comic
                        class="comic-module"
                        v-for="(comic, index) in historyComicsList"
                        :comic="comic"
                        :key="index + '' + comic.id"
                        :index="index"
                    />
                    <i></i>
                    <i></i>
                    <i></i>
                </div>
                <div class="no-more" v-if="!hasMore">没有更多啦~</div>
            </template>
        </div>
    </div>
</template>
<script>
import { get } from '@/modules/request.js';
import Comic from './Comic.vue';
import HeaderComp from '@/components/header/index.vue';

export default {
    data: function () {
        return {
            historyComicsList: [],
            historySince: 0,
            len: 12,
            hasMore: true,
            afterLoad: false,
            onRequest: false,
            from: '',
        };
    },
    components: {
        Comic,
        HeaderComp,
    },
    watch: {
        afterLoad(newVal) {
            if (newVal && this.hasMore) {
                this.bindScroll();
            }
        },
    },
    methods: {
        doBack() {
            if (this.from) {
                this.$router.back();
                return;
            }
            this.$router.replace({
                name: 'Index',
            });
        },
        async getHistory() {
            this.onRequest = true;
            const { data: historyInfo } = await get({
                url: process.env.VUE_APP_DISTRIBUTION_HOST + '/distribution/comic/history',
                data: {
                    since: this.historySince,
                    limit: this.len,
                    more_than_one: false,
                },
            });
            this.onRequest = false;
            this.hasMore = historyInfo.topics.length === this.len;
            this.historySince = historyInfo.since;
            if (!this.hasMore) {
                this.removeScroll();
            }
            this.historyComicsList = this.historyComicsList.concat(historyInfo.topics);
        },
        scrollHandler() {
            const scrollDom = document.querySelector('.recent-read .content-wrap');
            const { clientHeight, scrollTop, scrollHeight } = scrollDom;
            const leftDis = scrollHeight - clientHeight - scrollTop;
            if (this.hasMore && !this.onRequest && leftDis < 400) {
                this.getHistory();
            }
        },
        removeScroll() {
            const scrollDom = document.querySelector('.recent-read .content-wrap');
            scrollDom.removeEventListener('scroll', this.scrollHandler);
        },
        bindScroll() {
            const scrollDom = document.querySelector('.recent-read .content-wrap');
            scrollDom.addEventListener('scroll', this.scrollHandler);
        },
    },
    async created() {
        loading.show();
        document.title = '最近阅读';
        await this.getHistory();
        this.afterLoad = true;
        loading.hide();
        this.sa('CommonPageOpen', {
            uid: this.$store.state.userInfo.uid,
            CurPage: '最近阅读',
            PrePage: this.from,
        });
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.from = from.meta.trackName;
        });
    },
};
</script>
<style scoped>
.recent-read {
    height: 100vh;
}
.content-wrap {
    overflow-y: scroll;
    height: calc(100% - 48px);
}
.content-wrap .empty {
    margin: 79px auto 8px;
    width: 125px;
    height: 125px;
    background: url('./empty.png') no-repeat center/cover;
}
.content-wrap .msg {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    color: #ffd3d3;
    line-height: 17px;
}
.back-icon {
    position: absolute;
    top: 0;
    left: 0;
    padding: 12px;
    width: 24px;
    height: 24px;
    background: url('./icon_back.png') no-repeat center/24px;
}
.comics-list {
    padding: 0 3.2%;
    font-size: 0;
}
.comics-list i {
    flex-shrink: 0;
    width: 29.8vw;
    height: 0;
}
.comic-module {
    display: inline-block;
    margin-right: 1.25%;
    width: 32.5%;
    box-sizing: border-box;
}
.comic-module:nth-child(3n) {
    margin-right: 0;
}
.no-more {
    margin-top: 11px;
    padding-bottom: 20vh;
    font-size: 12px;
    text-align: center;
    color: #ccc;
}
</style>
