<template>
    <div class="single-comic" @click="comicClick()">
        <div class="comic-img">
            <img v-if="index < 12" :src="comic.verticalImageUrl | compress(300)" alt="" />
            <img v-else v-lazyLoad :data-src="comic.verticalImageUrl | compress(300)" alt="" />
            <ComicLabel v-if="showLabel" />
        </div>
        <div class="comic-intr">
            <div class="comic-title font-medium">{{ comic.title }}</div>
            <div class="read-record ell">{{ `阅读到：${comic.continueReadComic.title}` }}</div>
            <div class="update-record ell">{{ `更新至：第${comic.comicsCount}话` }}</div>
        </div>
    </div>
</template>
<script>
import ComicLabel from '@/components/label/index.vue';
import { mapGetters } from 'vuex';
export default {
    data: function () {
        return {};
    },
    components: {
        ComicLabel,
    },
    props: {
        comic: {
            type: Object,
            default() {
                return {};
            },
        },
        index: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        ...mapGetters(['canFeelVip']),
        showLabel() {
            return this.canFeelVip && this.comic.vipFree;
        },
    },
    methods: {
        comicClick() {
            const topicId = this.comic.id;
            const chapterId = this.comic.continueReadComic.id;
            this.$router.push({
                name: 'ComicRead',
                query: { comicId: chapterId, topicId: topicId },
                params: {
                    SourceModule: '阅读历史页',
                    TriggerPage: '最近阅读',
                },
            });
        },
    },
};
</script>
<style lang="less" scoped>
@import '~@/assets/vws.less';

.single-comic {
    display: inline-block;
    width: 100%;
    vertical-align: top;
}
.comic-img {
    position: relative;
    width: 100%;
    .vws(height, 318px);
}
.comic-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
    object-position: center;
}
.comic-intr {
    margin: 6px 0 12px;
}
.comic-title {
    overflow: hidden;
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #000;
    line-height: 17px;
}
.read-record {
    margin-top: 4px;
    font-size: 12px;
    line-height: 15px;
    color: #999;
}
.update-record {
    margin-top: 2px;
    font-size: 12px;
    line-height: 15px;
    color: #999;
}
</style>
